var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"cover"}),_c('div',{staticClass:"container-fluid cont p-1"},[_c('nav',[_c('div',{staticClass:"toggle",on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"fa fa-bars mx-3"}),_c('h5',[_vm._v("menu")])]),_c('div',{attrs:{"id":"menu"}},[_c('div',{staticClass:"link",class:{ active: !_vm.current },on:{"click":function () {
              _vm.$router.push('/dashboard');
              _vm.toggleMenu();
            }}},[_c('p',[_vm._v("Main")])]),_c('div',{staticClass:"link",class:{ active: _vm.current == 'site-data' },on:{"click":function () {
              _vm.$router.push('/dashboard/site-data');
              _vm.toggleMenu();
            }}},[_c('p',[_vm._v("Site Data")])]),_c('div',{staticClass:"link",class:{ active: _vm.current == 'transactions' },on:{"click":function () {
              _vm.$router.push('/dashboard/transactions');
              _vm.toggleMenu();
            }}},[_c('p',[_vm._v("Transactions")])]),_c('div',{staticClass:"link",class:{ active: _vm.current == 'systems' },on:{"click":function () {
              _vm.$router.push('/dashboard/systems');
              _vm.toggleMenu();
            }}},[_c('p',[_vm._v("Systems")])]),_c('div',{staticClass:"link",class:{ active: _vm.current == 'add-system' },on:{"click":function () {
              _vm.$router.push('/dashboard/add-system');
              _vm.toggleMenu();
            }}},[_c('p',[_vm._v("Add system")])]),_c('div',{staticClass:"link",class:{ active: _vm.current == 'categories' },on:{"click":function () {
              _vm.$router.push('/dashboard/categories');
              _vm.toggleMenu();
            }}},[_c('p',[_vm._v("Categories")])]),_c('div',{staticClass:"link",class:{ active: _vm.current == 'editAdminData' },on:{"click":function () {
              _vm.$router.push('/editAdminData');
              _vm.toggleMenu();
            }}},[_c('p',[_vm._v("Edit admin")])]),_c('div',{staticClass:"link",on:{"click":function () {
              _vm.$store.dispatch('logout');
              _vm.toggleMenu();
            }}},[_c('p',[_vm._v("Logout")])])])]),_c('div',{staticClass:"content container-fluid pt-5"},[_c('router-view')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }